import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import './styles/Contact.css';
import Footer from './Footer';

const ContactForm = () => {
  const [state, handleSubmit] = useForm("mayrdjjd");
  if (state.succeeded) {
      return <p className="message-submited">Thanks for considering us, our team will be intouch soon!</p>;
  }

  return (
    <div>
      <div className="header-home contact">
        <div className="contact-head">
          <h1>Get In Touch</h1>
          <p>Discover an unparalleled stay in the heart of Cape Town with Shelly Tour. Whether you choose to reach out to us via email at infor@shellytours.co.za or prefer the convenience of our user-friendly online form, rest assured that we are eager to curate the perfect experience for you. </p>
        </div>
        <div>
          
        </div>
      </div>
      <div className="form-div">
        <form onSubmit={handleSubmit}>
          <input
            id="email"
            type="email" 
            name="email"
            placeholder="Email"
          />
          <ValidationError 
            prefix="Email" 
            field="email"
            errors={state.errors}
          />
          <input
            id="name"
            type="name" 
            name="name"
            placeholder=" Full Name"
          />
          <ValidationError 
            prefix="name" 
            field="name"
            errors={state.errors}
          />
          <input
            id="phone"
            type="phone" 
            name="phone"
            placeholder="phone number (+27) South African Code"
          />
          <ValidationError 
            prefix="phone" 
            field="phone"
            errors={state.errors}
          />
          <textarea
            id="message"
            name="message"
            placeholder="Message"
          />
          <ValidationError 
            prefix="Message" 
            field="message"
            errors={state.errors}
          />
          <button type="submit" disabled={state.submitting}>
            Submit
          </button>
        </form>
      </div>
      <div className="payments">
        <p>For payment <a href="https://pay.yoco.com/shelly-tours" target="_blank">Click here</a></p>
      </div>
      <Footer />
    </div>
  );
};

export default ContactForm;
